const route = (event) => {
    event = event || window.event;
    event.preventDefault();
    window.history.pushState({}, "", event.target.href);
    handleLocation();
};

const routes = {
    404: "/pages/404.html",
    "/": "/pages/empreendimentos.html",
    "/guarapuava": "/pages/guarapuava.html",
    "/moises-marx": "/pages/moises-marx.html",
};

const scripts = {
    "/": "/js/empreendimentos.js",
    "/guarapuava": "/js/guarapuava.js",
    "/moises-marx": "/js/moises_marx.js",
};

const handleLocation = async () => {
    const path = window.location.pathname;
    const route = routes[path] || routes[404];
    const html = await fetch(route).then((data) => data.text());
    document.getElementById("ar-app-main").innerHTML = html;

    if(scripts[path]){
        let s = document.createElement("script");
            s.type = "text/javascript";
            s.src = "."+scripts[path]
        document.body.prepend(s);
    }
};

window.onpopstate = handleLocation;
window.route = route;

handleLocation();